.dream-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .dream-logo {
    animation: dream-logo-spin infinite 20s linear;
  }
}

.dream-description {
  height: 10vh;
  border-radius: 10px;
  padding: 20px;
}

.dream-analysis-row {
  background-color: #f5f7fa;
}

.dream-analysis {
  border-radius: 10px;
  text-align: justify;
  text-justify: inter-word;
  overflow-x: scroll;
  height: 250px;
}

.dream-img {
  max-height: 250px;
}

.submit-button {
  min-height: 40px;
  width: 75px;
  border-radius: 10px;
  background-color: #207bff;
  color: #f5f7fa;
  align-items: center;
  justify-content: center;
}

@keyframes dream-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
