/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: rgb(18, 18, 18) !important;
  color: white !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

.tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background: rgba(0, 0, 0, 0.4);
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: rgb(18, 18, 18);
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: white !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: white;
  border: 1px solid white;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: rgb(18, 18, 18);
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid white;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid white;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: white;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: white;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: white;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 200px 0 100px 0;
  background-image: url("./assets/img/banner-bg.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .content {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 50px;
  background: linear-gradient(
    90.21deg,
    rgba(20, 12, 56, 0.7) -5.91%,
    rgba(0, 0, 0, 0.7) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  text-align: justify;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h2 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner ul {
  color: rgb(184, 184, 184);
  font-size: 18px;
  letter-spacing: 0.8px;
  width: 96%;
}

.banner ul li {
  list-style-type: circle;
  line-height: 1.5em;
  padding: 10px 0px;
}
.banner button {
  color: white;
  font-weight: 700;
  font-size: 20px;
  margin-top: 30px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Resume / Skills Css ************/
.resume,
.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-box {
  background: linear-gradient(
    90.21deg,
    rgba(74, 47, 189, 0.5) -5.91%,
    rgba(107, 1, 63, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px;
  padding: 20px;
}

.resume-card img {
  width: auto;
  height: 75px;
  object-fit: scale-down;
  margin: 0 auto;
}

.skill-container {
  border: none;
  border-radius: 2rem;
  box-shadow: 10px;
  background-color: white;
}

.skill-card {
  border-radius: 20px;
}

.skill-card:hover {
  box-shadow: 0 4.5px 3.6px rgba(0, 0, 0, 0.08),
    0 12.5px 10px rgba(0, 0, 0, 0.115), 0 30.1px 24.1px rgba(0, 0, 0, 0.15),
    0 100px 80px rgba(0, 0, 0, 0.23);
  transition-delay: 0.5ms;
  background: linear-gradient(
    90.21deg,
    rgba(74, 47, 189, 0.7) -5.91%,
    rgba(107, 1, 63, 0.7) 111.58%
  );
}

.skill-card img {
  height: 75px;
}

.skill-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.resume-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 20px 20px;
  margin-top: -20px;
}
.resume h2 {
  font-size: 45px;
  font-weight: 700;
}
.resume p {
  color: rgb(184, 184, 184);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 15px 0 15px 0;
}
.resume-slider {
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.resume-slider .item img {
  width: 225px;
  margin: 0 auto 15px auto;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  padding: 20px;
  border-radius: 10px;
  height: 200x;
}
.resume .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
  @media only screen and (max-width: 1080px) {
    width: 100%;
  }
}
.resume .nav.nav-pills .nav-item {
  width: 33.3%;
}
.resume .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: white;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.resume .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

/* .resume .nav.nav-pills .nav-link span {
  padding-left: 8px;
} */

.resume .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.resume .nav.nav-pills .nav-link.active {
  /* border: 1px solid rgba(255, 255, 255, 1); */
}

.resume-card:hover {
  box-shadow: 0 4.5px 3.6px rgba(0, 0, 0, 0.08),
    0 12.5px 10px rgba(0, 0, 0, 0.115), 0 30.1px 24.1px rgba(0, 0, 0, 0.15),
    0 100px 80px rgba(0, 0, 0, 0.23);
  transition-delay: 0.5ms;
}
.resume-text {
  font-weight: 600;
  font-size: 14px;
  color: white;
  overflow: hidden;
}

.nav-link#skills-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#skills-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#skills-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: rgb(184, 184, 184);
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: white;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.modal {
  background: linear-gradient(
    90.21deg,
    rgba(20, 12, 56, 0.7) -5.91%,
    rgba(0, 0, 0, 0.7) 111.58%
  );
}

.project-modal {
  color: rgb(0, 153, 204);
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  cursor: zoom-in;
  height: 200px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.map-container {
  height: 200px;
}

.border-theme {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 55px;
  border: 4px solid transparent;
}

.contact {
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: white;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: rgb(18, 18, 18);
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: white;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: rgb(18, 18, 18);
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: black;
  background-color: white;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: white;
}
.contact form button::before {
  content: "";
  background: rgb(18, 18, 18);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url("./assets/img/footer-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.info-bx {
  background: linear-gradient(
    90.21deg,
    rgba(20, 12, 56, 0.7) -5.91%,
    rgba(0, 0, 0, 0.7) 111.58%
  );

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px;
  padding: 25px;
  margin-bottom: 40px;
  margin-top: -80px;
}

.info-container {
  border-radius: 55px;
  height: 200px;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.map-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.footer img.profile-pic {
  height: 200px;
  width: 200px;
  border-radius: 100%;
  /* @media only screen and (max-width: 600px) {
    text-align: center;
  } */
}

.footer .social-icon img {
  width: 26%;
}

img.logo {
  width: 15%;
}

.footer p {
  color: rgb(184, 184, 184);
  letter-spacing: 0.5px;
}

.footer .personal-info p a {
  text-decoration: none;
  color: rgb(0, 153, 204);
}

.footer .personal-info p .icon {
  border: 2px solid #ffffff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin-right: 10px;
  padding: 10px;
}

.footer p.greeting {
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
}

.leading-normal {
  line-height: 1.5;
}

.text-align-center {
  text-align: center;
}

/************ Education & Experience Css ************/
.timeline {
  position: relative;
}

.timeline .timelin-title {
  font-size: 20px;
  text-transform: uppercase;
  margin-left: 25px;
}

.timeline li {
  padding: 0 20px 20px 30px;
  list-style: none;
  border-left: 1px solid gray;
}

.timeline li .content-text:hover {
  box-shadow: 0 4.5px 3.6px rgba(0, 0, 0, 0.08),
    0 12.5px 10px rgba(0, 0, 0, 0.115), 0 30.1px 24.1px rgba(0, 0, 0, 0.15),
    0 100px 80px rgba(0, 0, 0, 0.23);
  transition-delay: 0.5ms;
  background: linear-gradient(
    90.21deg,
    rgba(74, 47, 189, 0.7) -5.91%,
    rgba(107, 1, 63, 0.7) 111.58%
  );
}

.timeline li .content-text {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px;
  padding: 20px;
  background: rgb(0, 153, 204);
}

.timeline li .line-title {
  font-size: 16px;
  text-overflow: ellipsis;
}

.timeline li span {
  font-size: 12px;
  padding: 9px 0;
  display: block;
}

.timeline li .line-text {
  color: white;
  font-size: 18px;
  line-height: 18px;
  @media (min-width: 1080px) {
    font-size: 14px;
  }
}

.timeline li:first-child:before {
  border: 0;
  background: none;
  position: relative;
}

.timeline li:last-child {
  padding: 0 20px 0px 30px;
}

.timeline li:before {
  content: "";
  border: solid 2px;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  font-size: 8px;
  margin-left: -35px;
  font-weight: 400;
  background: #fff;
  display: block;
  position: absolute;
  margin-top: 18px;
  border-color: rgb(0, 153, 204) !important;
}

.timeline li:first-child i {
  font-size: 20px;
  float: left;
  margin-left: -30px;
  line-height: 50px;
  border-radius: 30px;
  margin: -12px -55px;
  background: rgb(0, 153, 204);
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
}
